// Bootstrap brand color customization


/*     brand Colors              */

$brand-primary:              $imo-primary !default;
// $brand-primary:              $purple-500 !default;
$brand-info:                 $cyan-500 !default;
$brand-success:              $green-500 !default;
$brand-warning:              $yellow !default;
$brand-danger:               $red !default;
// $brand-rose:                 $pink-500 !default;
$brand-inverse:              $black-color !default;
$brand-rose:                 $imo-primary !default;
