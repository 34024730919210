:root {
  --green: #22bca4;
  --green-dark: #004f54;
  --green-background: #2cb3a171; //#2cb3a03f;
  --green-light: #22bca40c;
  --green-lighter: #ecf7ef;
  --green-violet: rgba(34, 188, 164, 0.15);
  --yellow: #f3c05c;
  --red: #eb5e5a;
  --red-light: #fdefee;
  --red-dark: rgba(235, 94, 90, 0.15);
  --red-dark-light: rgba(235, 94, 90, 0.1);
  --gray-dark: #314149;
  --gray: #616b7a;
  --gray2: #718096;
  --gray-disabled-background: #cccccc;
  --gray-disabled-text: #999899;
  --gray-light: #e9ecef;
  --gray-white: #9a9fa5;
  --gray-violet: rgba(225, 231, 236, 0.5);
  --gray-bg: #efefef;
  --gray-lighter: #c3cdd8;
  --gray-lightest: #e1e7ec;
  --gray-the-lightest: #eaebec;
  --gray-light-2: #8d949f;
  --gray-light-3: #d0d5dd;
  --disabled-gray: #bbbbbb;
  --blue: #257993;
  --blue-dark: #0b455d;
  --blue-violet: #425466;
  --blue-light: #b8f2e9;
  --primary-color: var(--green);
  --secondary-color: var(--green-background);
  --tertiary-color: var(--green-light);
  --alert: var(--yellow);
  --danger: var(--red);
  --light-blue: #edf2f7;
  --off-white: #fbfbfb;
  --black: #555;
  --alert-bg: #f3c05c33;
  --table-row-bg: #f6fafd;
  --form-field-border: #eeeeee;
  --white-transparent: rgba(255, 255, 255, 0.5);
  --white: #ffffff;
  --white-light: #e5e5e5;
  --danger-bg: #eb5e5a1a;
  --info-bg: #32b4fe1a;
  --gray-shadow: #d4d4d440;
  --warning-text: #f0ae2c;
  --banner-bg: var(--green-dark);
  --floral-white: #f9f7f0;
  --rhino-black: #3c4858;
  --radical-red: #ff3266;
  --label-color: #000000de;
  --bkbn-orange: #e06e49;
  --bkbn-orange-light: #ee8c48;
  --bkbn-gray: #7e8c8d;
}

.tile-image-service,
.tile-image-package,
.cart-item-thumbnail {
  color: var(--green-dark) !important;
  --color_fill: #abd0c9 !important;
  --color-stroke: var(--white);
}
