/* You can add global styles to this file, and also import other style files */

.btn-back,
.btn-back:active,
.btn-back:focus,
.btn-back:hover {
  background-color: var(--gray) !important;
  border-color: var(--gray) !important;
}

body {
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.active-link {
  color: #2cb3a0 !important;
}

.my-link {
  color: #2cb3a0 !important;
}

.margintop {
  margin-top: 0.5em;
}

.m {
  margin-bottom: 0em !important;
}

.card-no-pad {
  padding-top: 0em;
}

.nomarbot {
  margin-bottom: 0.125em;
}

.nomartop {
  margin-bottom: 0.125em;
}

.my-icon {
  margin-right: 0 !important;
  background-color: transparent !important;
  height: 16px !important;
  width: 16px !important;
  font-size: 1.02em !important;
}

.hovernavicon:hover {
  color: #2cb3a0 !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
}

/* h1, h2, h2, h3, h4, h5, h6 {
  font-weight: 400!important;
} */

.alert {
  font-size: 0.85rem;
  margin: 0em;
}

/* CSS - LOADING ANIMATION */

.lds-ring {
  display: inline-block;
  position: relative;
}

.ring-small {
  width: 20px;
  height: 20px;
}

.ring-default {
  width: 64px;
  height: 64px;
}

.ring-default div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #2cb3a0;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2cb3a0 transparent transparent transparent;
}

.ring-small div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 26px;
  height: 26px;
  margin: 3px;
  border: 3px solid #2cb3a0;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2cb3a0 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* SmartModals */

.nsm-dialog {
  top: 25%;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  position: absolute;
  min-height: 200px;
}

.clickable {
  cursor: pointer;
}

.no-cursor {
  cursor: default;
}

.centered {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.btn-modal {
  margin-top: 0.5em;
}

.font-sz-20 {
  font-size: 20px;
}

.form-control.ng-invalid.ng-touched {
  border-color: var(--danger);
  background-size: 100% 100%, 100% 100%;
}

.details-list .row {
  padding: 5px 0 5px 0;
}

.details-list > .row:nth-child(even) {
  background-color: #f6fafd;
}

.comments-section {
  border: 1px solid;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.uploaded-img,
.download-layer {
  height: 110px;
  width: 110px;
}

.download-layer {
  z-index: 10;
  opacity: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 0.8rem;
  top: 0;
  left: 0;
  padding-left: 5px;
}

.download-layer:hover {
  opacity: 1;
}

.file-download {
  position: absolute;
  bottom: 5px;
  left: 20px;
  color: #fff;
}

.attachment {
  margin-right: 15px;
  position: relative;
  border: 1px solid;
  border-radius: 10%;
  overflow: hidden;
}

.details-list .logo-thumbnail {
  height: 50px;
  width: 9rem;
}

.details-list .style-image {
  align-items: center;
}

strong {
  font-weight: bold !important;
}

.page-title {
  font-size: 1.55rem;
}

.add-new-realestate .add-new-realestate-body,
.realestate-card-body {
  padding: 0.9375rem 0.5rem !important;
}

.add-new-realestate {
  height: 73%;
  min-height: 171px;
  background: transparent !important;
  border: 2px dashed var(--primary-color) !important;
  cursor: pointer;
}

.add-new-realestate .add-new-realestate-body i {
  font-size: 8rem;
  color: var(--primary-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-mdc-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: var(--primary-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background: var(--primary-color) !important;
}

.card-header.specification-header,
.card-header.product-detail-header {
  border-radius: 5px !important;
  background: rgba(44, 179, 160, 0.5) !important;
  padding: 7px 7px !important;
}

.package-details {
  max-height: 300px;
  overflow-y: auto;
}

.font-small-400 {
  font-weight: 400;
  font-size: small;
}

.font-300 {
  font-weight: 300;
  font-size: large;
}

.font-200 {
  font-weight: 200;
  font-size: small;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-size-normal {
  font-size: medium;
  font-weight: normal;
}

.font-size-large {
  font-size: large;
}

.font-size-xlarge {
  font-size: x-large;
}

.font-size-xxlarge {
  font-size: xx-large;
}

.imogent-gray {
  color: var(--gray);
}

.imogent-grey-lighter {
  color: var(--gray-lighter);
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-disabled {
  opacity: 0.5;
}

.element-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.file-delete {
  position: absolute;
  left: 85px;
  bottom: 10px;
  font-size: 14px;
}

.markerjs-logo {
  display: none !important;
}

.panel-title-color .mat-expansion-panel-header,
.panel-title-color .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover {
  background: rgba(44, 179, 160, 0.25) !important;
}

.mat-expansion-panel-body {
  padding-top: 12px !important;
}

textarea.form-control {
  background-image: none;
  border: 1px solid var(--gray-lighter);
  border-radius: 10px;
  min-height: 130px;
  padding-left: 10px;
}

textarea.form-control:focus {
  border-color: var(--primary-color);
}

/* textarea.form-control::-webkit-input-placeholder,
textarea.form-control::-moz-placeholder,
textarea.form-control:-ms-input-placeholder,
textarea.form-control::-ms-input-placeholder,
textarea.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
} */

/* Fixme: use imo-file-upload-container */
.fileContainer {
  min-height: 150px;
  border: 1px dashed var(--primary-color);
  color: black !important;
  align-items: center;
  border-radius: 10px;
  background-color: var(--light-blue);
}

.fileContainer i {
  font-size: 50px;
  color: var(--gray);
}

.fileContainer {
  overflow: hidden;
  position: relative;
}

.fileContainer [type="file"] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

mat-expansion-panel-header:not(.mat-expanded) .mat-expansion-panel-header-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preserve-new-line {
  white-space: pre-line;
}

.text-clip {
  text-overflow: ellipsis;
  overflow: hidden;
}

.mediumSize {
  max-width: 15vw;
  margin-top: 2vh;
}

.file-list .file-entry {
  border-radius: 5%;
  padding: 5px 0 5px 0;
}

.file-list .file-entry:nth-child(odd) {
  background-color: #e5f9f6;
}

.file-list .file-entry .file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-list {
  max-height: 150px;
  overflow-y: auto;
}

.file-list .file-entry .file-remove {
  cursor: pointer;
}

.ps__rail-y {
  opacity: 0.6 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--primary-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button .mat-radio-label .mat-radio-ripple .mat-ripple-element {
  background-color: var(--primary-color) !important;
}
.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: #2cb3a1 !important;
}

.mat-mdc-option.mat-mdc-option-multiple.mat-mdc-selected .mat-mdc-option-pseudo-checkbox:after {
  color: #fff !important;
}

.mat-mdc-option.mat-mdc-option-multiple .mat-pseudo-checkbox-checked,
.mat-mdc-option.mat-mdc-option-multiple .mat-pseudo-checkbox-indeterminate {
  background: #23b2a0 !important;
}

.mat-mdc-select-panel.panel-custom-width {
  max-width: unset !important;
}

.mat-mdc-option[aria-disabled="true"],
.mat-mdc-option[aria-disabled="true"]:hover,
.mat-mdc-option[aria-disabled="true"]:focus,
.mat-mdc-option-disabled,
.mat-mdc-option-disabled:hover {
  background-color: var(--gray-bg);
  cursor: no-drop;
  color: var(--gray-the-lightest);
  box-shadow: none;
}

:host ::ng-deep .info-tooltip .tooltip-inner {
  background-color: var(--primary-color);
  color: #fff !important;
}

.sectionHeader {
  font-size: 1.3rem;
  font-weight: 400;
  color: var(--primary-color);
}

.sectionHeaderGrey {
  font-size: 1.3rem;
  font-weight: 400;
  color: #616b7a;
}

.step-title {
  color: var(--blue-violet);
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
}
.subHeaderGrey {
  font-size: 1.1rem;
  font-weight: 400;
  color: #616b7a;
}

.sectionHeaderLight {
  font-size: 1.3rem;
  font-weight: 400;
  color: #c3cdd8;
}

.pseudoAnchor {
  opacity: 0;
  position: absolute;
  top: 50px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
mat-button-toggle-group.serviceStepper {
  box-shadow: none;
  padding: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
mat-button-toggle.serviceStepper {
  border: 1px rgba(0, 0, 0, 0.1) solid !important;
  margin-top: 0px !important;
  padding: 0 5px !important;
  z-index: 3 !important;
  height: 30px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
mat-button-toggle:hover.serviceStepper {
  border: 1px var(--primary-color) solid !important;
  border-radius: 0px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-checked.serviceStepper {
  box-shadow: 0 0 2px #000 !important;
  border: 1px var(--primary-color) solid !important;
  border-radius: 0px !important;
  background-color: var(--primary-color) !important;
  color: white;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-input.serviceStepper {
  background-color: none !important;
  padding-left: 32px !important;
}

.hidden {
  position: absolute;
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  top: -9999em;
}

.not-hidden {
  position: static;
  opacity: 1;
  transition: all 1s;
}

div.annotationFrame {
  width: 200px;
  height: 110px;
  background: var(--alert);
  position: relative;
  font-size: small;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
}
div.annotationFrame:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  border-top: 55px solid transparent;
  border-bottom: 55px solid transparent;
  border-right: 20px solid var(--alert);
  font-size: small;
  color: white;
}

div.annotationFrameXL {
  width: 200px;
  height: 140px;
  background: var(--alert);
  position: relative;
  font-size: small;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 22px;
}
div.annotationFrameXL:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  border-top: 70px solid transparent;
  border-bottom: 70px solid transparent;
  border-right: 20px solid var(--alert);
  font-size: small;
  color: white;
}

div.annotationFrameXS {
  width: 200px;
  height: 40px;
  background: var(--alert);
  position: relative;
  font-size: small;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
}
div.annotationFrameXS:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid var(--alert);
  font-size: small;
  color: white;
}

.maxH100 {
  max-height: 100px;
}

.maxH200 {
  max-height: 200px;
}
.maxH240 {
  max-height: 240px;
}

.maxH40 {
  max-height: 40px;
}

.mw-25 {
  max-width: 25%;
}

.text-larger {
  font-size: larger;
}

.background-warning {
  background: var(--alert) !important;
}

.bg-black {
  background: var(--black) !important;
}

.display-table {
  display: table !important;
}

.table-center-vertically {
  display: table-cell;
  vertical-align: middle;
}

.lineHeightSmall {
  line-height: 1.3;
}

.fat-font {
  font-size: 2.2rem;
  font-weight: bold;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 99999 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
.mat-chip-option {
  background-color: var(--green) !important;
  color: #ffffff !important;
}

.mat-mdc-option:hover {
  background: var(--light-blue) !important;
  border-radius: 5px;
}

.form-group .date-range-picker .mat-date-range-input {
  position: relative;
  top: 5px;
  left: 10px;
  font-size: small;
}

.form-group .date-range-picker mat-datepicker-toggle {
  position: absolute;
  right: 5px;
  top: -5px;
}

.large-title-text {
  font-size: 1rem;
  font-weight: 500;
}

.small-title-text {
  font-size: 0.8rem;
  font-weight: 500;
}

.font-small {
  font-size: small;
}

.light-blue-bg {
  background: var(--light-blue);
}

.light-red-bg {
  background: var(--red-dark);
}

.light-green-bg {
  background: var(--green-light);
}
.text-gray {
  color: var(--gray) !important;
}

.text-imo {
  color: var(--green-dark) !important;
}

.text-light-gray-2 {
  color: var(--gray-light-2);
}

.text-black {
  color: var(--black);
}

.text-light-gray {
  color: var(--gray-light);
}

.white-bg {
  background: var(--white);
}

::ng-deep .mat-mdc-select-panel {
  padding: 10px !important;
}
.mat-select-panel.company-long-panel {
  max-height: 530px;
}

.image-circle {
  border-radius: 50%;
}

.order-checkout-custom-modal {
  max-width: 1200px !important;
}

.font-smaller {
  font-size: smaller;
}

.fileContainer.error,
.form-control.error {
  border-color: var(--danger);
}

app-stepper .mat-horizontal-stepper-header-container .mat-step-header[aria-selected="true"] {
  background-color: var(--green-background) !important;
  border-radius: 10px !important;
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover:not([aria-disabled]),
.mat-step-header:hover[aria-disabled="false"] {
  background-color: unset !important;
}
.warning-background-light {
  background-color: var(--alert-bg);
}

.warning-text {
  color: var(--warning-text);
}

.cursor-pointer {
  cursor: pointer;
}

.mt-40-px {
  margin-top: 40px;
}

.green-background-light {
  background-color: var(--green-background);
}

app-stepper .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
  width: unset !important;
}

app-register .mat-mdc-select-placeholder,
app-register .mat-mdc-select .mat-mdc-select-value-text span,
app-register .form-control,
app-register input::placeholder {
  font-weight: 600 !important;
  color: var(--rhino-black) !important;
  font-size: 14px !important;
}

app-register .mdc-form-field > .mdc-radio ~ label {
  font-weight: 600 !important;
  color: var(--rhino-black) !important;
}

app-register .form-group .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-value-text {
  position: inherit !important;
}
app-orderoverview mat-tab-group:not(.order-list-tabs) .mat-tab-label {
  height: unset !important;
  padding: unset !important;
}
.mat-icon {
  overflow: unset !important;
}

.consent-banner .mat-mdc-dialog-container {
  border-radius: 18px !important;
  height: inherit;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

/*Manipulate background color on hover and focus*/
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

mat-pseudo-checkbox {
  border: solid 2px !important;
  color: var(--primary-color) !important;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--gray);
}

.mat-mdc-option .mdc-list-item__primary-text {
  color: var(--gray);
  /**
  truncate text of option list items instead of wrapping it to the next line.
   */
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

/* Change the background color of all matTooltips */
.mdc-tooltip__surface {
  background-color: var(--white) !important;
}

/* Change tooltip text color to black */
.cdk-overlay-container .mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-color: var(--label-color);
}

/**
TODO: Check if it is a propper way to define the modal styles on a global level.
 */
.mat-mdc-dialog-container .mdc-dialog__content {
  font-family: var(--mdc-dialog-supporting-text-font, Roboto, "sans-serif");
  line-height: var(--mdc-dialog-supporting-text-line-height, 1.5em);
  font-size: var(--mdc-dialog-supporting-text-size, 0.9rem);
  font-weight: var(--mdc-dialog-supporting-text-weight, 400);
  letter-spacing: var(--mdc-dialog-supporting-text-tracking, unset);
}

.mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 1.5em;
  --mdc-dialog-supporting-text-size: 0.9rem;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 1);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 1);
}

.mat-mdc-dialog-title .mdc-dialog__title {
  font: 500 20px/32px Roboto, Helvetica Neue, sans-serif;
}

.matDialogContainer {
  padding: 15px;
  border-radius: 15px;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

mat-dialog-title {
  margin: 0 0 20px;
  display: block;
  font: 500 20px/32px Roboto, Helvetica Neue, sans-serif;
  font-weight: 500;
  font-size: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-mdc-dialog-title {
  --mdc-dialog-subhead-font: "Roboto";
  --mdc-dialog-subhead-line-height: 20px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0px;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  font-family: var(--mdc-dialog-subhead-font, "Arial");
  line-height: var(--mdc-dialog-subhead-line-height, 14px);
  font-size: var(--mdc-dialog-subhead-size, 14px);
  font-weight: var(--mdc-dialog-subhead-weight, 500);
  letter-spacing: var(--mdc-dialog-subhead-tracking, 1px);
}

.mdc-form-field > label {
  margin-top: auto;
  margin-bottom: auto;
  color: var(--black);
}

.font-size-14 {
  font-size: 14px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-unselected-focus-handle-color: var(--black);
  --mdc-switch-unselected-hover-handle-color: var(--black);
  --mdc-switch-unselected-pressed-handle-color: var(--black);
}

.min-w-160 {
  min-width: 160px;
}

.text-transform-none {
  text-transform: none;
}

.section-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: var(--gray-dark);
}

.uploadArea {
  margin: 0;
}

.buttonUpload {
  padding: 0px;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.uploadHeader {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.btn-bkbn-orange {
  color: #fff;
  background-color: var(--bkbn-orange-light); /* Orange shade */
  border-color: var(--bkbn-orange-light);
  box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2),
    0 1px 5px 0 rgba(255, 152, 0, 0.12);
}

.btn-bkbn-orange:hover {
  background-color: var(--bkbn-orange); /* Darker shade on hover */
  border-color: var(--bkbn-orange);
}
.btn-bkbn-orange:active,
.btn-bkbn-orange:focus {
  background-color: var(--bkbn-orange-light); /* Slightly darker for active/focused state */
  border-color: var(--bkbn-orange-light);
  box-shadow: 0 4px 6px 0 rgba(255, 152, 0, 0.2), 0 3px 5px -2px rgba(255, 152, 0, 0.3);
}

.btn-bkbn-orange:disabled {
  background-color: #ffc68a; /* Light shade for disabled */
  border-color: #ffc68a;
  color: #fff;
  cursor: not-allowed; /* Indicates the button is not clickable */
}
