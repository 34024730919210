/*!

 =========================================================
 * Material Dashboard PRO Angular - v2.1.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-angular2
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import "../../../node_modules/@angular/material/theming";
@include mat-core();
$imogent-platform-primary-palette: (
  50: #e4f7f4,
  100: #bdebe4,
  200: #91ded2,
  300: #64d0bf,
  400: #43c6b2,
  500: #22bca4,
  600: #1eb69c,
  700: #19ad92,
  800: #14a589,
  900: #0c9778,
  A100: #c5fff1,
  A200: #92ffe4,
  A400: #5fffd8,
  A700: #46ffd1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$imogent-platform-accent-palette: (
  50: #f2fbfa,
  100: #def5f2,
  200: #c8efe9,
  300: #b2e8e0,
  400: #a2e3d9,
  500: #91ded2,
  600: #89dacd,
  700: #7ed5c7,
  800: #74d1c1,
  900: #62c8b6,
  A100: #ffffff,
  A200: #ffffff,
  A400: #d0fff6,
  A700: #b7fff1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$imogent-platform-primary: mat-palette($imogent-platform-primary-palette);
$imogent-platform-accent: mat-palette($imogent-platform-accent-palette);
$imogent-platform-theme: mat-light-theme(
  (
    color: (
      primary: $imogent-platform-primary,
      accent: $imogent-platform-accent,
    ),
  )
);

// Apply theme colors to @angular/material components colors
@include angular-material-theme($imogent-platform-theme);

$theme-colors: (
  primary: #22bca4,
  primary-light: #2cb3a03f,
  tertiary: #22bca40c,
);

@import "core/variables";
@import "core/mixins";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "core/reboot";

// Core Components
@import "core/layout";
@import "core/buttons";
@import "core/checkboxes";
@import "dashboard/cards";
@import "core/custom-forms";
@import "core/switches";
@import "core/example-pages";
@import "core/radios";
@import "core/carousel";
@import "dashboard/forms";
@import "core/input-group";
@import "core/list-group";
@import "core/nav";
@import "core/images";
@import "dashboard/navbar";
@import "core/badges";
@import "dashboard/alerts";
/* @import "core/pagination"; */
@import "core/pills";
@import "core/info-areas";
@import "core/type";
@import "core/tabs";
@import "core/footers";
@import "core/tooltip";
@import "core/popover";
@import "core/modal";
@import "core/dropdown";
@import "core/headers";
@import "core/drawer";
@import "core/progress";
@import "core/togglebutton";
@import "core/ripples";
@import "dashboard/rtl";
@import "dashboard/sidebar-and-main-panel";
@import "dashboard/timeline";
@import "dashboard/fixed-plugin";
@import "dashboard/tables";
@import "dashboard/misc";
@import "dashboard/pages";

//plugin css
@import "plugins/wizard-card";
@import "plugins/select-bootstrap";
@import "plugins/perfect-scrollbar";

@import "dashboard/responsive";
