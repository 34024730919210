// .mat-horizontal-stepper-header {
//     &[ng-reflect-active="true"]+.mat-stepper-horizontal-line {
//         border-top-color: #2cb3a1 !important;
//     }
// }
@import "_variables";
@import "./styles/navcss.css";
@import "../node_modules/ngx-smart-modal/ngx-smart-modal";
@import "../node_modules/ng-pick-datetime-ex/assets/style/picker.min.css";
@import "./assets/fonts/fonts.scss?family=Material+Icons";
@import "./assets/scss/core/variables";
// @import "variables";

// Fixes Angular Material checkbox labels line breaks
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout {
  white-space: normal !important;
}

// use style="--columns-gap: 5px"
.two-columns-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--columns-gap, 10px);
}
.three-columns-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: var(--columns-gap, 10px);
}
.four-columns-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: var(--columns-gap, 10px);
}
.five-columns-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: var(--columns-gap, 10px);
}
.six-columns-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: var(--columns-gap, 10px);
}
.seven-columns-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: var(--columns-gap, 10px);
}
.eight-columns-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: var(--columns-gap, 10px);
}

.alert b {
  text-transform: none !important;
}

label[required-asterisk]:after {
  content: " *";
  color: var(--color, inherit);
  position: relative;
  //opacity: 0.7;
}

@mixin materialIcon {
  //noinspection CssNoGenericFontName
  font-family: "Material Icons", "none";
  font-weight: normal;
  font-style: normal;
  text-transform: none;
}

@mixin btn-imogent-pill {
  --button-size: 2rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0;
  height: var(--button-size);
  padding: calc(var(--button-size) * 0.5) calc(var(--button-size) * 0.5) calc(var(--button-size) * 0.5)
    var(--button-size) !important;
  font-weight: 700;
}
@mixin btn-imogent-pill-icon {
  @include materialIcon;
  font-weight: normal;
  font-style: normal;
  text-transform: none;
  font-size: var(--button-size);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  height: 100%;
  align-items: center;
  line-height: 0;
}
.btn-imogent-pill-add {
  @include btn-imogent-pill;
  &:hover {
    background-color: var(--primary-color) !important;
  }
  &:before {
    @include btn-imogent-pill-icon;
    content: "add_circle";
  }
}

.btn-imogent-pill-remove {
  @include btn-imogent-pill;
  &:hover {
    background-color: var(--danger) !important;
  }
  &:before {
    @include btn-imogent-pill-icon;
    content: "remove_circle";
  }
}

.imogent-checkbox-container {
  background-color: var(--gray-lightest);
  color: var(--gray);

  &.invalid {
    border: 2px solid var(--danger) !important;
    background-color: var(--danger-bg);
  }
  &.selected {
    background-color: var(--green-background);
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    & .mat-checkbox-layout,
    .mat-slide-toggle-label {
      color: var(--green);
    }
  }
  & .mat-mdc-slide-toggle {
    height: auto;
    width: 100%;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  & .mat-checkbox-layout,
  .mat-slide-toggle-label {
    white-space: normal !important;
    font-style: italic;
    margin-bottom: 0 !important;
    align-items: center;
    padding: 8px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  & .mat-slide-toggle-content {
    white-space: normal !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  & .mat-checkbox-inner-container,
  .mat-slide-toggle-bar {
    margin: 1rem;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  & .mat-checkbox-frame {
    border-color: var(--gray-light);
  }
}

.mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: var(--white);
  --mdc-checkbox-unselected-icon-color: var(--gray);
  --mdc-checkbox-unselected-pressed-icon-color: var(--gray);
  --mdc-checkbox-unselected-hover-icon-color: var(--gray);
  --mdc-checkbox-unselected-focus-icon-color: var(--gray);
}

.mat-mdc-slider.mat-primary {
  --mdc-slider-inactive-track-color: var(--gray);
}

.mdc-checkbox__background {
  --mdc-checkbox-selected-icon-color: var(--primary-color);
}

.mdc-radio {
  --mdc-radio-selected-icon-color: var(--primary-color);
  --mat-mdc-radio-checked-ripple-color: var(--primary-color);
  --mdc-radio-selected-focus-icon-color: var(--primary-color);
  --mdc-radio-selected-hover-icon-color: var(--primary-color);
  --mdc-radio-selected-pressed-icon-color: var(--primary-color);
}

.mdc-slider {
  --mdc-slider-label-container-color: var(--primary-color);
}

.fab-imogent {
  --fab-size: 1.6rem;
  --fab-top: auto;
  --fab-right: auto;
  --fab-bottom: auto;
  --fab-left: auto;
  position: absolute;
  top: var(--fab-top);
  right: var(--fab-right);
  bottom: var(--fab-bottom);
  left: var(--fab-left);
  font-size: var(--fab-size);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

label[required-asterisk]:after {
  content: "*";
  color: red;
  position: relative;
  right: -4px;
  opacity: 0.7;
}

.required-field:after,
label.required-field:after {
  content: "*";
  color: var(--danger);
  position: relative;
  right: -4px;
  opacity: 0.7;
}

.form-group {
  span.input-error {
    font-size: x-small;
  }
  .mat-mdc-select {
    border: 1px solid var(--gray-lighter);
    border-radius: 5px;
    height: 36px;
    .mat-mdc-select-trigger {
      height: 100%;
      .mat-mdc-select-value {
        .mat-mdc-select-value-text {
          position: relative;
          left: 5px;
        }
        .mat-mdc-select-placeholder {
          font-size: 12px;
        }
      }
    }
    &.mat-mdc-select-disabled {
      opacity: 0.5;
    }
    &.ng-invalid.ng-touched {
      border-color: var(--danger);
    }
  }
  &.imo-date-picker {
    .mat-datepicker-toggle {
      position: absolute;
      bottom: 9px;
      right: 0;
    }
  }
  .form-control[disabled] {
    opacity: 0.5;
  }
  input {
    border: 1px solid var(--gray-lighter);
    border-radius: 5px;
    height: 36px;
  }
}

.bg-primary-light {
  background-color: lighten($imo-primary, 20%) !important;
}

.bg-white {
  background-color: var(--white) !important;
}

.bg-orange {
  background-color: var(--bkbn-orange) !important;
}

.bg-bkbn-gray {
  background-color: var(--bkbn-gray) !important;
}

.border-primary {
  border: 1px solid var(--green) !important;
}
.navbar {
  box-shadow: none !important;
}
app-package-selector .mat-mdc-tab-header,
app-service-selector .mat-tab-header,
app-analytics .mat-tab-header,
app-orderoverview .sub-order-tab > .mat-tab-header,
app-orderoverview .order-list-tabs > .mat-tab-header {
  background-color: #ffffff !important;
  top: 0 !important;
  z-index: 999 !important;
  position: fixed !important;
  margin-top: $imogent-margin-top-header;
  width: 100%;
  max-width: -moz-available; /* WebKit-based browsers will ignore this. */
  max-width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  box-shadow: 0px 10px 10px rgba(184, 184, 184, 0.25);
}

// pushing main content up when navbar is disabled
router-outlet.navbar-disabled + * {
  .mat-mdc-tab-header {
    margin-top: 0px !important;
  }
}

.mat-mdc-tab-header {
  margin-left: -8px !important;
}

app-order-checkout .shadow.card {
  > .card-body {
    background-color: var(--off-white) !important;
  }
}

app-package-selector .mat-mdc-tab-body,
app-service-selector .mat-tab-body {
  margin: $imogent-margin-top-body 5px 0px 5px;
}

.fixed-top {
  left: 88px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-content {
  height: 100%;
  overflow: auto;
}

.mat-step-icon {
  border-radius: 24% !important;
  height: 30px !important;
  width: 30px !important;
  flex-shrink: 0;
  position: relative;
}
.mat-step-text-label {
  width: 157px;
  height: 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: black;
  :active {
    color: var(--primary-color) !important;
  }
}

.mat-step-label-selected {
  .mat-step-text-label {
    color: var(--primary-color) !important;
  }
}

app-stepper .mat-step-text-label {
  height: unset !important;
  width: unset !important;
}

.mat-step-header .mat-step-icon {
  background-color: white !important;
  color: black !important;
}
.mat-horizontal-stepper-header .mat-step-icon {
  border: 1px solid var(--gray-light-3);
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  color: white !important;
  background-color: var(--primary-color) !important;
  border: none !important;
}
.disabled-stepper .mat-step-icon.mat-step-icon-state-done {
  background-color: gray !important;
}
.mat-stepper-horizontal,
.mat-stepper-vertical {
  background: none !important;
}
.mat-horizontal-content-container {
  padding: 0 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label-active {
  color: var(--primary-color) !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-package-selector .mat-tab-label,
app-service-selector .mat-tab-label,
app-analytics .mat-tab-label,
app-board .mat-tab-label,
app-orderoverview .mat-tab-label {
  opacity: unset !important;
  color: var(--gray);
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-service-selector .mat-tab-label:last-child {
  margin-left: auto !important;
}
.bg-danger-light {
  background: var(--danger-bg);
}

.bg-info-light {
  background: var(--info-bg);
}

.bg-alert-light {
  background: var(--alert-bg);
}

app-stepper .mat-horizontal-stepper-header .mat-step-icon {
  border: none !important;
}

.card.cgrid {
  margin-top: 0.8rem !important;
}
app-board .mat-mdc-tab-header {
  margin-right: -8px !important;
}

.object-fit-contain {
  object-fit: contain !important;
}
.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primary-color) !important;
  color: #000000 !important;
  height: 4px !important;
  border-radius: 10px !important;
}

.uploadArea .card-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: var(--gray-dark);
}
